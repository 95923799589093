import React, { useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Image,
  FormControl,
  Text,
  FormHelperText,
  Link,
  InputRightElement,
} from "@chakra-ui/react";
import { FaLock, FaUserAlt } from "react-icons/fa";
import { Context } from "../ContextWrapper";
import PasswordChecklist from "react-password-checklist";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const ResetPassword = ({ register }) => {
  const [searchParams] = useSearchParams();
  const context = React.useContext(Context);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPassValid, setIsPassValid] = useState(false);

  const [password1, setPassword1] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);

  const handleShowClick1 = () => setShowPassword1(!showPassword1);
  const handleShowClick2 = () => setShowPassword2(!showPassword2);

  const s = searchParams.get("s");

  const submit = async (e) => {
    e.preventDefault();
    const data = {
      s: s,
      email: email,
      password: password1,
    };
    await axios
      .post(
        "/api/v2/resetpassword/",
        data,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setDisabled(true);
        if (res.data.reset) {
          var header = "Reset password";
          var msg = "Password reset successfully";
          if (register) {
            header = "Create password";
            msg = "Password created, please go to the login page";
          }
          context.fireToast(header, msg, "success");
        } else {
          if (register) {
            context.fireToast(
              "Create password",
              "Password not create.",
              "error"
            );
          } else {
            context.fireToast("Reset password", "Password not reset.", "error");
          }
        }
      });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={submit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <Box w="400px">
                <Image src="lucro-logo-light.png" alt="Dan Abramov" />
              </Box>
              <Text>Enter your email and your new password.</Text>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword1 ? "text" : "password"}
                    placeholder="Password"
                    value={password1}
                    required
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick1}>
                      {showPassword1 ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Password"
                    value={password2}
                    required
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick2}>
                      {showPassword2 ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={5}
                  value={password1}
                  valueAgain={password2}
                  onChange={(isValid) => {
                    setIsPassValid(isValid);
                  }}
                />
                <FormHelperText textAlign="right">
                  <Link href="/login">go to login</Link>
                </FormHelperText>
              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="blue"
                width="full"
                isDisabled={disabled || !isPassValid}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
