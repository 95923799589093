import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Stack,
  Container,
  Heading,
  Flex,
  Box,
  Spacer,
  HStack,
  Button,
  Td,
  InputGroup,
  Input,
  InputRightElement,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Spinner,
  Checkbox,
  IconButton,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import CustomTableContainer from "../../theme/components/tableContainer";
import { Context } from "../../ContextWrapper";
import axios from "axios";
import { ToggleLeft, ToggleRight, Trash } from "react-feather";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { DropDown } from "../generic/dropDown";
import { titleCase } from "../../utils";

export const UserManagement = () => {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [search, setSearch] = useState("");
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLinkClientsOpen,
    onOpen: onLinkClientsOpen,
    onClose: onLinkClientsClose,
  } = useDisclosure();
  const [currentProfile, setCurrentProfile] = useState({});
  const context = React.useContext(Context);

  const refreshProfiles = useCallback(
    (cursor) => {
      setLoading(true);
      let params = {};
      if (cursor) {
        const urlObj = new URL(cursor);
        params.cursor = urlObj.searchParams.get("cursor");
      }

      if (search) {
        params.user__username__icontains = search;
      }

      axios
        .get("/api/v2/profiles/", {
          headers: { "Content-Type": "application/json" },
          params: params,
          withCredentials: true,
        })
        .then((res) => {
          setProfiles(res.data.results);
          setNextCursor(res.data.next);
          setPreviousCursor(res.data.previous);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    },
    [search]
  );

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
      refreshProfiles();
    }
  }, [refreshProfiles]);

  const handleNextPage = () => {
    if (nextCursor) {
      refreshProfiles(nextCursor);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      refreshProfiles(previousCursor);
    }
  };

  const updateProfile = (profile) => {
    setProfiles(profiles.map((p) => (p.id === profile.id ? profile : p)));
  };

  const toggleProfile = (profile) => {
    axios
      .patch(`/api/v2/profiles/${profile.id}/`, {
        is_active: !profile.is_active,
      })
      .then((res) => {
        updateProfile(res.data);
      })
      .catch((err) => console.log(err));
  };

  const toggleAdmin = (profile) => {
    axios
      .patch(`/api/v2/profiles/${profile.id}/`, {
        is_admin: !profile.is_admin,
      })
      .then((res) => {
        updateProfile(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSubmitProfile = (event, profile) => {
    event.preventDefault();
    setLoading(true);

    if (profile.id) {
      axios
        .patch(`/api/v2/profiles/${profile.id}/`, profile)
        .then((res) => {
          setProfiles(
            profiles.map((p) => (p.id === res.data.id ? res.data : p))
          );
          closeProfilePopup();
          context.fireToast("Success", "User has been updated", "success");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/v2/profiles/`, profile)
        .then((res) => {
          setProfiles([...profiles, res.data]);
          closeProfilePopup();
          context.fireToast("Success", "User has been created", "success");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmitLinkClients = (event, linkedCustomers) => {
    event.preventDefault();

    axios
      .patch(`/api/v2/profiles/${currentProfile.id}/`, {
        customers: linkedCustomers,
      })
      .then((res) => {
        setProfiles(profiles.map((p) => (p.id === res.data.id ? res.data : p)));
        context.fireToast(
          "Success",
          "User client list has been updated.",
          "success"
        );
        closeLinkClientsPopup();
      })
      .catch((err) => console.log(err));
  };

  const startUpdateProfile = (profile) => {
    setCurrentProfile(profile);
    onOpen();
  };

  const startLinkClients = (profile) => {
    setCurrentProfile(profile);
    onLinkClientsOpen();
  };

  const closeProfilePopup = () => {
    onClose();
    setCurrentProfile({});
  };

  const closeLinkClientsPopup = () => {
    onLinkClientsClose();
    setCurrentProfile({});
  };

  const renderCustomersColumn = (profile) => {
    if (
      profile.type.toLowerCase() === "accountant" ||
      profile.type.toLowerCase() === "client"
    ) {
      return (
        <Wrap>
          {profile.customers.map((c) => (
            <WrapItem>{c.name}</WrapItem>
          ))}
        </Wrap>
      );
    }
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="90%">
          <ProfilePopup
            profile={currentProfile}
            isOpen={isOpen}
            onClose={closeProfilePopup}
            loading={loading}
            handleSubmit={handleSubmitProfile}
          />
          <LinkClientsPopup
            profile={currentProfile}
            isOpen={isLinkClientsOpen}
            onClose={closeLinkClientsPopup}
            loading={loading}
            handleSubmit={handleSubmitLinkClients}
          />
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                User profiles
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <HStack p="0" direction="row" spacing={2} align="center">
                <Button ps={7} pe={7} leftIcon={<AddIcon />} onClick={onOpen}>
                  Create User
                </Button>
                <InputGroup minW="340px">
                  <Input placeholder="Search..." onChange={handleSearch} />
                  <InputRightElement
                    bg="brand.500"
                    borderBottomRightRadius={6}
                    borderTopRightRadius={6}
                  >
                    <SearchIcon variant="white" />
                  </InputRightElement>
                </InputGroup>
              </HStack>
            </Box>
          </Flex>
          <Box maxHeight="calc(100vh - 300px)" bg="white" overflowX="auto">
            <Table variant="unstyled">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Email</Th>
                  <Th>Type</Th>
                  <Th>Clients</Th>
                  <Th>Active</Th>
                  <Th>New Acc features</Th>
                  <Th style={{ width: "118px" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {profiles.map((profile) => (
                  <Tr key={profile.id}>
                    <Td>{profile.user.username}</Td>
                    <Td>{titleCase(profile.type)}</Td>
                    <Td>{renderCustomersColumn(profile)}</Td>
                    <Td>
                      {profile.is_active && (
                        <ToggleRight
                          color="green"
                          onClick={() => toggleProfile(profile)}
                        />
                      )}
                      {!profile.is_active && (
                        <ToggleLeft
                          color="red"
                          onClick={() => toggleProfile(profile)}
                        />
                      )}
                    </Td>
                    <Td>
                      {profile.is_admin && (
                        <ToggleRight
                          color="green"
                          onClick={() => toggleAdmin(profile)}
                        />
                      )}
                      {!profile.is_admin && (
                        <ToggleLeft
                          color="red"
                          onClick={() => toggleAdmin(profile)}
                        />
                      )}
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button onClick={() => startUpdateProfile(profile)}>
                          Edit
                        </Button>
                        {(profile.type.toLowerCase() === "accountant" ||
                          profile.type.toLowerCase() === "client") && (
                          <Button onClick={() => startLinkClients(profile)}>
                            Clients
                          </Button>
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Stack direction="row" spacing={4} mt={4} justifyContent="center">
            {previousCursor && (
              <Button onClick={handlePreviousPage}>Previous</Button>
            )}
            {nextCursor && (
              <Button onClick={handleNextPage} disabled={!nextCursor}>
                Next
              </Button>
            )}
          </Stack>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const ProfilePopup = ({ profile, isOpen, onClose, loading, handleSubmit }) => {
  const confirmText = !profile?.id ? "Create" : "Update";
  const [profileData, setProfileData] = useState({});
  const [changePassword, setChangePassword] = useState(false);
  const [sendInvite, setSendInvite] = useState(true);

  const types = [
    { value: "client", label: "Client" },
    { value: "admin", label: "Admin" },
    { value: "accountant", label: "Accountant" },
  ];

  useEffect(() => {
    const { customers, ...rest } = profile;
    setProfileData(rest);
    setChangePassword(false);
  }, [profile]);

  const handleChangePassword = (e) => {
    setChangePassword(e.target.checked);

    if (!e.target.checked) {
      setProfileData((current) => {
        const { password, password2, ...rest } = current;
        return rest;
      });
    }
  };

  const handleSendInvite = (e) => {
    setSendInvite(e.target.checked);

    if (!e.target.checked) {
      setProfileData((current) => {
        const { password, password2, ...rest } = current;
        return rest;
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleSelectType = (type) => {
    setProfileData({ ...profileData, type: type.value.toLowerCase() });
  };

  const validPasswords =
    (profile?.id && !changePassword) ||
    (profileData.password && profileData.password === profileData.password2) ||
    sendInvite;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{confirmText} profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="profileForm" onSubmit={(e) => handleSubmit(e, profileData)}>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="text"
                name="username"
                defaultValue={profileData?.user?.username}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Type</FormLabel>
              <DropDown
                name="type"
                options={types}
                onChange={handleSelectType}
                selectedValue={profileData.type}
              />
            </FormControl>
            {profile?.id && (
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  name="changePassword"
                  isChecked={changePassword}
                  onChange={handleChangePassword}
                >
                  Change password
                </Checkbox>
              </FormControl>
            )}
            {!profile?.id && (
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  name="sendInvite"
                  isChecked={sendInvite}
                  onChange={handleSendInvite}
                >
                  Send Invite
                </Checkbox>
              </FormControl>
            )}
            {((!profile?.id && !sendInvite) || changePassword) && (
              <>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="text"
                    name="password"
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Confirm password</FormLabel>
                  <Input
                    type="text"
                    name="password2"
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="profileForm"
            isDisabled={loading || !validPasswords}
            ml={3}
          >
            {loading ? <Spinner size="sm" /> : confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const LinkClientsPopup = ({
  profile,
  isOpen,
  onClose,
  loading,
  handleSubmit,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [linkedCustomers, setLinkedCustomers] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/v2/customers/`, {
        headers: { "Content-Type": "application/json" },
        params: { having_profile: profile.id },
        withCredentials: true,
      })
      .then((res) => {
        setLinkedCustomers(
          res.data.map((c) => ({ value: c.id, label: c.name }))
        );
      })
      .catch((err) => console.log(err));

    axios
      .get(`/api/v2/customers/`, {
        headers: { "Content-Type": "application/json" },
        params: { not_having_profile: profile.id },
        withCredentials: true,
      })
      .then((res) => {
        setCustomers(res.data.map((c) => ({ value: c.id, label: c.name })));
      })
      .catch((err) => console.log(err));
  }, [profile]);

  const handleSelectCustomer = (customer) => {
    setCustomers((prev) => prev.filter((c) => c.value !== customer.value));
    setLinkedCustomers([...linkedCustomers, customer]);
    setSelectedCustomer(null);
  };

  const removeCustomer = (customer) => {
    setLinkedCustomers((prev) =>
      prev.filter((c) => c.value !== customer.value)
    );
    setCustomers([...customers, customer]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage accountant clients</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            id="profileForm"
            onSubmit={(e) => handleSubmit(e, linkedCustomers)}
          >
            <FormControl>
              <FormLabel htmlFor="customer">Add client</FormLabel>
              <DropDown
                name="customer"
                options={customers}
                selectedValue={selectedCustomer}
                onChange={handleSelectCustomer}
              />
            </FormControl>
          </form>
          <Box
            mt={5}
            maxHeight="calc(100vh - 480px)"
            bg="white"
            overflowX="auto"
          >
            <Table variant="unstyled" size="sm">
              <Thead>
                <Tr>
                  <Td>Linked Clients</Td>
                  <Td></Td>
                </Tr>
              </Thead>
              <Tbody>
                {linkedCustomers.map((c) => (
                  <Tr key={c.value}>
                    <Td pt="4px" pb="4px">
                      {c.label}
                    </Td>
                    <Td pt="4px" pb="4px" w="40px">
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        icon={<Trash />}
                        onClick={() => removeCustomer(c)}
                        variant="outline"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button type="submit" form="profileForm" isDisabled={loading} ml={3}>
            {loading ? <Spinner size="sm" /> : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
