import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spacer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useMediaQuery,
  useOutsideClick,
  useTab
} from "@chakra-ui/react";
import {
  ArrowLeft,
  ArrowRight,
  BarChart,
  Home,
  LogOut,
  Minus,
  Plus,
  Settings
} from "react-feather";
import { ClientPlaid } from "../client/clientPlaid";
import { GrowthPlan } from "./growthPlan";

export const ClientNavigation = ({ customers }) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const [customerInfo, setCustomerInfo] = useState({});
  const [isClientSelectOpen, setClientSelectOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isGrowthToolsOpen, setIsGrowthToolsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingssOpen] = useState(false);
  const [isLogoutSelected, setIsLogoutSelected] = useState(false);
  const [dashboardDate, setDashboardDate] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState(isMobile ? true : false);
  const [userInput, setUserInput] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tabs] = useState(["Growth Plan", "Performance Review"]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  const handleSearch = () => {
    // Filter customers based on the search query
    const results = customers.filter((customer) =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCustomers(results);
  };

  // Filtered options based on input
  const filteredTabs = tabs.filter((tab) =>
    tab.toLowerCase().includes(userInput.toLowerCase())
  );

  // Close dropdown when clicking outside
  const dropdownRef = React.useRef();
  const clientSelectRef = React.useRef();

  useOutsideClick({
    ref: clientSelectRef,
    handler: () => setClientSelectOpen(false),
  });

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsDropdownOpen(false),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (customers) {
      setCustomerInfo(customers[0]);
    }
  }, [customers]);

  return (
    <Tabs
      orientation="vertical"
      isLazy
      minH={"100vh"}
      index={selectedIndex}
      onChange={(index) => setSelectedIndex(index)}
    >
      <TabList
        position={"fixed"}
        visibility={!visible && isMobile && "hidden"}
        top="0"
        left="0"
        height="100vh"
        zIndex={1000}
        borderRight={"1px solid #e0e0e0"}
        bg="white"
        color="black"
        pl={6}
        pr={6}
        width={visible ? "350px" : !isMobile ? "100px" : "0px"}
      >
        <Flex h="80px" alignItems={"center"} justifyContent={"space-between"}>
          {visible ? (
            <Box align="left">
              <Image
                onClick={() => navigate("/")}
                boxSize="170px"
                objectFit="contain"
                src="../lucro-logo-light.png"
                cursor={"pointer"}
              />
            </Box>
          ) : (
            <></>
          )}

          <Box
            align="left"
            cursor={"pointer"}
            onClick={() => {
              setVisible(!visible);
              setIsDashboardOpen(false);
              setIsGrowthToolsOpen(false);
              setIsSettingssOpen(false);
            }}
          >
            <Box
              p={1}
              border={"2px solid #F5F5F5"}
              backgroundColor={"#FBFBFB"}
              borderRadius={"12px"}
            >
              {!visible ? (
                <ArrowRight
                  height={"100%"}
                  width="38px"
                  fontSize={"9px"}
                  fontWeight={100}
                />
              ) : (
                <ArrowLeft
                  height={"100%"}
                  width="38px"
                  fontSize={"9px"}
                  fontWeight={100}
                />
              )}
            </Box>
          </Box>
        </Flex>

        <VStack
          width={"100%"}
          align="stretch"
          spacing="2"
          overflow="visible"
          maxHeight={"40%"}
          mb={2}
          position="relative"
        >
          <HStack
            pt={2}
            width={"100%"}
            pl={visible ? 4 : 1}
            onClick={() => {
              setClientSelectOpen(true);
              setVisible(true);
            }}
            cursor={"pointer"}
          >
            <Flex width={"100%"} align="center">
              <Image
                src={
                  customerInfo.logo ||
                  `https://ui-avatars.com/api/?size=24&name=${customerInfo.name}`
                }
                alt={customerInfo.name || "Placeholder"}
                boxSize="42px"
                objectFit="cover"
                borderRadius="full"
                mr={visible ? 2 : 0}
              />
              {visible ? (
                <HStack
                  fontSize={"16px"}
                  width={"100%"}
                  justifyContent="space-between"
                  pl={2}
                >
                  <Text textAlign={"left"}>{customerInfo.name}</Text>
                  <ChevronDownIcon fontSize={"16px"} mr={2} />
                </HStack>
              ) : null}
            </Flex>
          </HStack>

          {visible ? (
            <Box
              position={"absolute"}
              top="100%"
              left="0"
              zIndex="1000"
              width={"100%"}
              marginTop={2}
              border={0}
              boxShadow={"lg"}
              borderRadius={"10px"}
              ref={clientSelectRef}
            >
              <Collapse in={isClientSelectOpen} animateOpacity>
                <Box
                  bg="white"
                  p={4}
                  zIndex="1000"
                  maxH={"300px"}
                  overflow="hidden"
                  borderRadius={"10px"}
                >
                  <Box>
                    <InputGroup size="md">
                      <Input
                        placeholder="Search..."
                        borderRadius="5px"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <InputRightElement width="20%">
                        <Button
                          h="100%"
                          w="100%"
                          bg="#30384A"
                          color="white"
                          borderRadius="0 5px 5px 0"
                          _hover={{ bg: "gray.800" }}
                          onClick={handleSearch}
                        >
                          <SearchIcon boxSize="100%" />
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Box>

                  {/* Options List with Scrollbar */}
                  <Box
                    mt={2}
                    mb={2}
                    maxH="180px"
                    overflowY="auto"
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#30384A",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "gray.100",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer, index) => (
                        <Text
                          key={index}
                          pt={1}
                          pb={2}
                          pl={2}
                          mb={"2px"}
                          borderRadius={"5px"}
                          textDecoration={
                            customerInfo?.id === customer?.id
                              ? "underline"
                              : "none"
                          }
                          _hover={{
                            bg: "#EEF1FE",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCustomerInfo(customer);
                          }}
                        >
                          {customer.name}
                        </Text>
                      ))
                    ) : (
                      <Text pt={2} pb={2} pl={2} color="gray.500">
                        No customers found.
                      </Text>
                    )}
                  </Box>
                </Box>
              </Collapse>
            </Box>
          ) : (
            <></>
          )}
        </VStack>

        <VStack zIndex={5}>
          {visible ? (
            <Box
              width={"100%"}
              m={4}
              position="relative"
              ref={dropdownRef}
              bg="white"
              borderRadius={"12px"}
            >
              {/* Input Field */}
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  pl={7}
                  fontSize={"25px"}
                  color={"black"}
                  height={"55px"}
                >
                  <SearchIcon color="gray.500" />
                </InputLeftElement>
                <Input
                  border={0}
                  value={userInput}
                  onChange={(e) => {
                    setUserInput(e.target.value);
                    setIsDropdownOpen(true);
                    setVisible(true);
                  }}
                  placeholder={visible ? "Search..." : ""}
                  onFocus={() => setIsDropdownOpen(true)}
                  bg="gray.100"
                  color="black"
                  fontSize="16px"
                  borderTopRadius={"12px"}
                  height={"55px"}
                  borderBottomRadius={isDropdownOpen ? "0px" : "12px"}
                  pl="14" // Add padding for space between icon and text
                  _focus={{
                    boxShadow: "0 0 0 0px #3182ce", // Light blue outline for accessibility
                    border: "none",
                  }}
                />
              </InputGroup>

              {/* Dropdown Suggestions */}
              {isDropdownOpen && filteredTabs.length > 0 && (
                <Box
                  bg="gray.100"
                  position="absolute"
                  top="100%"
                  left="0"
                  width="100%"
                  overflowY={"auto"}
                  borderBottomRadius="12px"
                  maxH={"20vh"}
                  zIndex={10}
                >
                  <List spacing={1}>
                    {filteredTabs.map((tab, index) => (
                      <ListItem
                        key={index}
                        p={2}
                        pl={6}
                        height={12}
                        _hover={{
                          bg: "gray.100",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setUserInput(tab); // Select the tab
                          setSelectedIndex(index);
                        }}
                      >
                        <HStack>
                          <Minus />
                          <Text>{tab}</Text>
                        </HStack>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              {/* No Results Message */}
              {isDropdownOpen && filteredTabs.length === 0 && (
                <Box
                  bg="gray.100"
                  position="absolute"
                  top="100%"
                  left="0"
                  width="100%"
                  overflowY={"auto"}
                  borderBottomRadius="12px"
                  maxH={"20vh"}
                  zIndex={10}
                >
                  <Box height={12} p={2}>
                    <HStack pl="4">
                      <Minus />
                      <Text>No matching tabs found.</Text>
                    </HStack>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Button
              width={"100%"}
              fontSize={"25px"}
              m={4}
              position="relative"
              ref={dropdownRef}
              bg="gray.100"
              height={"50px"}
              onClick={() => {
                setIsDropdownOpen(true);
                setVisible(true);
              }}
              p={3}
              _hover={{ bg: "gray.100" }}
              borderRadius={"12px"}
            >
              <SearchIcon color="gray.500" />
            </Button>
          )}
        </VStack>

        <VStack
          align="stretch"
          spacing="2"
          overflowY={"hidden"}
          maxHeight={"40%"}
          mb={2}
        >
          <Box
            bg={isDashboardOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <Button
              w="100%"
              justifyContent="space-between"
              variant="ghost"
              textColor={isDashboardOpen ? "white" : "#30384A"}
              borderRadius={"12px"}
              onClick={() => {
                setIsDashboardOpen(!isDashboardOpen);
                setVisible(true);
              }}
              bg={isDashboardOpen ? "#30384A" : "transparent"}
              _hover={{ bg: "#30384A", color: "white" }}
              _selected={{ bg: "#30384A", color: "white" }}
              p={visible ? 6 : 3}
            >
              <Flex align="center">
                <Home mr={visible ? 2 : 0} p={3} />
                {visible ? <Text pl={3}>Dashboard</Text> : <></>}
              </Flex>
              {visible ? (
                isDashboardOpen ? (
                  <Minus pl={9} fontSize="40px" />
                ) : (
                  <Plus pl={9} fontSize="40px" />
                )
              ) : (
                <></>
              )}
            </Button>
            <Collapse in={isDashboardOpen} animateOpacity>
              <Box bg="gray.100" py="2" borderBottomRadius={"12px"}>
                <CustomTab justifyContent="stretch">
                  <Minus mr="2" />
                  <Text pl={2}>Growth Plan</Text>
                </CustomTab>
                <CustomTab justifyContent="stretch">
                  <Minus mr="2" />
                  <Text pl={2}>Performance Review</Text>
                </CustomTab>
              </Box>
            </Collapse>
          </Box>
          <Box
            bg={isGrowthToolsOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <Button
              w="100%"
              justifyContent="space-between"
              variant="ghost"
              textColor={isGrowthToolsOpen ? "white" : "#30384A"}
              borderRadius={"12px"}
              onClick={() => {
                setIsGrowthToolsOpen(!isGrowthToolsOpen);
                setVisible(true);
              }}
              bg={isGrowthToolsOpen ? "#30384A" : "transparent"}
              _hover={{ bg: "#30384A", color: "white" }}
              _selected={{ bg: "#30384A", color: "white" }}
              p={visible ? 6 : 3}
            >
              <Flex align="center">
                <BarChart mr="2" p={3} />
                <Text pl={3}>{visible ? "Growth Tools" : ""}</Text>
              </Flex>
              {visible ? (
                isGrowthToolsOpen ? (
                  <Minus pl={9} fontSize="40px" />
                ) : (
                  <Plus pl={9} fontSize="40px" />
                )
              ) : (
                <></>
              )}
            </Button>
            <Collapse in={isGrowthToolsOpen} animateOpacity>
              <Box bg="gray.100" py="2" borderBottomRadius={"12px"}>
                <CustomTab justifyContent="stretch" isDisabled>
                  <Minus mr="2" />
                  <Text pl={2}>...Coming Soon</Text>
                </CustomTab>
              </Box>
            </Collapse>
          </Box>
        </VStack>
        <Spacer />
        <VStack align="stretch" spacing="2" paddingBottom={2}>
          <Box
            bg={isSettingsOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <Collapse in={isSettingsOpen} animateOpacity>
              <Box bg="gray.100" py="2" borderTopRadius={"12px"}>
                <Flex justifyContent="stretch">
                  <CustomTab justifyContent="stretch">
                    <Minus mr="2" />
                    <Text pl={2}>Bank & Subscription</Text>
                  </CustomTab>
                </Flex>
              </Box>
            </Collapse>
            <Button
              w="100%"
              justifyContent="space-between"
              variant="ghost"
              borderRadius={"12px"}
              textColor={isSettingsOpen ? "white" : "#30384A"}
              onClick={() => {
                setIsSettingssOpen(!isSettingsOpen);
                setVisible(true);
              }}
              bg={isSettingsOpen ? "#30384A" : "transparent"}
              _hover={{ bg: "#30384A", color: "white" }}
              _selected={{ bg: "#30384A", color: "white" }}
              p={visible ? 6 : 3}
            >
              <Flex align="center">
                <Settings mr="2" />
                <Text pl={3}>{visible ? "Settings" : ""}</Text>
              </Flex>
            </Button>
          </Box>
          <Box>
            <Link className="nav-link" to="/logout">
              <Button
                w="100%"
                justifyContent="space-between"
                variant="ghost"
                onClick={() => setIsLogoutSelected(true)}
                textColor={isLogoutSelected ? "white" : "#30384A"}
                borderRadius={"12px"}
                bg={isLogoutSelected ? "#30384A" : "transparent"}
                _hover={{ bg: "#30384A", color: "white" }}
                _selected={{ bg: "#30384A", color: "white" }}
                p={visible ? 6 : 3}
              >
                <Flex align="center">
                  <LogOut mr="2" />
                  <Text pl={3}>{visible ? "Log Out" : ""}</Text>
                </Flex>
              </Button>
            </Link>
          </Box>
        </VStack>
        <Box pl={visible ? 2 : 0} mb="5" pr={visible ? 2 : 0}>
          <Divider color="gray.50" />

          <HStack pt={2}>
            <Flex>
              {/* Left element */}
              <Image
                src={
                  customerInfo.logo ||
                  `https://ui-avatars.com/api/?size=24&name=${customerInfo.name}`
                }
                alt={customerInfo.name || "Placeholder"}
                boxSize="50px"
                objectFit="cover"
                borderRadius="full"
                mr={visible ? 2 : 0}
              />

              {/* Right stacked elements */}
              {visible ? (
                <Box>
                  <Text>{customerInfo.name}</Text>
                  <Text>{customerInfo.email}</Text>
                </Box>
              ) : (
                <></>
              )}
            </Flex>
          </HStack>
        </Box>
      </TabList>
      <TabPanels>
        <TabPanel p="0" ml={visible ? (isMobile ? "0px" : "350px") : (isMobile ? "0px" : "100px")}>
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <GrowthPlan
                customer_id={customerInfo.id}
                dateFromNav={dashboardDate}
                setDateFromNav={setDashboardDate}
                setNavIndex={setSelectedIndex}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel p="0" ml={visible ? (isMobile ? "0px" :"350px") : (isMobile ? "0px" : "100px")}>
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <GrowthPlan
                customer_id={customerInfo.id}
                dateFromNav={dashboardDate}
                setDateFromNav={setDashboardDate}
                setNavIndex={setSelectedIndex}
                performanceToggle={true}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel p="0" ml={visible ? (isMobile ? "0px" :"350px") : (isMobile ? "0px" : "100px")}>
          {/* Need this for the coming soon tab in growth tools */}
        </TabPanel>
        <TabPanel p="0" ml={visible ? (isMobile ? "0px" :"350px") :  (isMobile ? "0px" : "100px")}>
          <Box h="calc(100vh - 80px)">
            {customerInfo && <ClientPlaid customer_id={customerInfo.id} setNavVisible={setVisible}/>}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const CustomTab = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });

  return (
    <Button
      w="290px"
      bg="grey.100"
      textColor={"#30384A"}
      _hover={{ bg: "grey.100" }}
      _selected={{ bg: "grey.100", color: "blue" }}
      _focus={{ color: "blue" }}
      _active={{ bg: "grey.100", color: "blue" }}
      {...tabProps}
    >
      {tabProps.children}
    </Button>
  );
});
