import { Box, Card, CardBody, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";

const ExpenseCard = ({
  title = "",
  percentage = "0.00",
  total = "0.00",
  perfectPL = "0.00",
  color = "black"
}) => {
  return (
    <Stack width="100%" align="center" p="10px 10px">
      <Card width="100%" height="150px" borderRadius="20px">
        <CardBody padding={2}>
          <Box align={"center"}>
            <Text fontSize={"large"} margin={0}>
              {title}
            </Text>
            <Text fontSize={"clamp(28px, 5vw, 32px)"} fontWeight={800} margin={0} color={color}>
              {percentage}
            </Text>
          </Box>
          <SimpleGrid templateColumns={"1fr 1fr"} align="center">
            <Box p={0} borderRightWidth={"2px"} height={"57px"} m={0}>
              <Text fontSize={"md"} margin={0} gridColumn={1} gridRow={1}>
                Perfect P&L
              </Text>
              <Text
                fontSize={"clamp(18px, 5vw, 22px)"}
                fontWeight={600}
                margin={0}
                gridColumn={1}
                gridRow={2}
                whiteSpace={"nowrap"}
              >
                Below {perfectPL}
              </Text>
            </Box>
            <Box p={0}>
              <Text fontSize={"md"} margin={0} gridColumn={2} gridRow={1}>
                Total
              </Text>
              <Text
                fontSize={"clamp(18px, 5vw, 22px)"}
                fontWeight={600}
                margin={0}
                gridColumn={2}
                gridRow={2}
                whiteSpace={"nowrap"}
              >
                {total}
              </Text>
            </Box>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default ExpenseCard;
