import React, { useState } from "react";
import axios from "axios";
import { Button, IconButton, Spinner, Tooltip } from "@chakra-ui/react";
import { LinkIcon, RepeatIcon } from "@chakra-ui/icons";
import { usePlaidLink } from "react-plaid-link";

export const LinkPlaidButton = ({
  customer_id,
  refreshList,
  plaiditem_id,
}) => {
  const [loading, setLoading] = useState(false);
  const [publicToken, setPublicToken] = useState("");

  const openPlaidLink = () => {
    setLoading(true);
    let params = {};
    if (plaiditem_id) {
      params.plaiditem_id = plaiditem_id;
    }

    axios
      .get(`/api/v2/create_link_token/`, {
        headers: { "Content-Type": "application/json" },
        params: params,
        withCredentials: true,
      })
      .then((res) => {
        setPublicToken(res.data.link_token);
      })
      .catch((err) => console.log(err));
  };

  const close = () => {
    setLoading(false);
    setPublicToken(null);
  }

  return (
    <>
      {plaiditem_id ? (
        <Tooltip label="Click to Relink Plaid Item." fontSize="md">
          <IconButton
            icon={loading ? <Spinner size="sm" /> : <RepeatIcon />}
            aria-label="Refresh"
            onClick={openPlaidLink}
          />
        </Tooltip>
      ) : (
        <Button leftIcon={loading ? <Spinner size="sm" /> : <LinkIcon boxSize={4} />} onClick={openPlaidLink}>
          Link bank account
        </Button>
      )}
      {publicToken && (
        <LinkPlaid
          customer_id={customer_id}
          refreshList={refreshList}
          public_token={publicToken}
          close={close}
          refresh={plaiditem_id !== undefined}
        />
      )}
    </>
  );
};

const LinkPlaid = ({
  customer_id,
  refreshList,
  public_token,
  close,
  refresh,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnSuccess = (public_token, metadata) => {
    axios
      .post("/api/v2/exchange_public_token/", {
        public_token: public_token,
        customer_id: customer_id,
        refresh: refresh,
      })
      .then((res) => {
        refreshList(customer_id)
        close();
        setIsOpen(false);
      });
  };

  const handleEvent = (eventName, metadata) => {
    if (eventName === "EXIT") {
      close(null);
      setIsOpen(false);
    }
  };

  const config = {
    onSuccess: handleOnSuccess,
    onExit: (err, metadata) => {},
    onEvent: handleEvent,
    token: public_token,
  };
  const { open, ready } = usePlaidLink(config);

  if (ready && !isOpen) {
    setIsOpen(true);
    open();
  }

  return <></>;
};
