import { Card, Grid, Stack, Tab, TabList, Tabs, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import MetricView from "./metricView";
import DetailView from "./detailView";
import CalendarComponent from "./calendarComponent";

const PerformanceReview = ({
  customer_id,
  dashDate,
  trigger,
  triggerSetter,
  updateDate,
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const [tab, setTab] = useState("metric");

  return (
    <Stack>
      <Card borderRadius={0} h="50px" border="1px solid #e0e0e0" mt={isMobile? "125px" : "75px"} zIndex={300} position={"fixed"}>
        <Tabs>
          <Grid
            templateColumns="1fr 2fr 9fr"
            width="100%"
            align="center"
            p={!isMobile ? "15px 20px 0px": "15px 10px 0px"}
          >
            <TabList>
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                }}
                cursor="pointer"
                onClick={() => setTab("metric")}
                _active={{
                  background: "none",
                }}
                width="90px"
                h="35px"
                p={0}
              >
                <Text fontWeight="bold" margin={"0px 0px 5px 0px"}>
                  Metric View
                </Text>
              </Tab>
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                }}
                cursor="pointer"
                onClick={() => setTab("detail")}
                _active={{
                  background: "none",
                }}
                width="105px"
                align="left"
                h="35px"
                p={0}
                whiteSpace="nowrap"
              >
                <Text fontWeight="bold" margin={"0px 0px 5px 0px"}>
                  Detail View
                </Text>
              </Tab>
            </TabList>
            <Stack gridColumn={9} textAlign={"right"}>
              { !isMobile && <CalendarComponent
                callbackFunctionDate={updateDate}
                triggerSetter={triggerSetter}
                date={dashDate}
                height="25px"
              />}
            </Stack>
          </Grid>
        </Tabs>
      </Card>
      {tab === "metric" ? (
        <MetricView
          customer_id={customer_id}
          dashDate={dashDate}
          trigger={trigger}
          triggerSetter={triggerSetter}
          triggerExpenseBreakdown={triggerExpenseBreakdown}
          setBreakdownData={setBreakdownData}
        />
      ) : (
        <DetailView
          customer_id={customer_id}
          dashDate={dashDate}
          trigger={trigger}
          triggerSetter={triggerSetter}
        />
      )}
    </Stack>
  );
};

export default PerformanceReview;
