import React, { useState, useEffect } from "react"
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import { Box } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useCurrentProfile } from "../../ContextWrapper";


export const ClientDropDown = ({customer_id}) => {
  const [customerList, setCustomerList] = useState([]);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const { profile } = useCurrentProfile();

  useEffect(() => {
    if(localStorage.getItem('access_token') === null){
      window.location.href = '/login'
    }
    else {
      let params = {};
      if (profile?.hasAccountingAccess && profile?.type === "accountant") {
        params.having_profile = profile.id;
      } else if (profile?.type === "client") {
        params.having_profile = profile.id;
      }

      axios
      .get("/api/v2/customers/", {
        headers: {'Content-Type': 'application/json'},
        params: params,
        withCredentials: true,
      })
      .then((res) => {
        const items = res.data.filter(
          (item) => item.is_active === true)
        .map(
          (item) => {return {"value": item.id, "label": item.name}}
        );
        setCustomerList(items);
        setSelected(items.find((c) => c.value === customer_id))
      })
      .catch((err) => console.log(err));
    }
  }, [customer_id, profile]);

  return (
    <Box mt={5} w="275px" ml="5">
      {selected && selected.name}
      <Select
        name="client"
        options={customerList}
        value={selected}
        placeholder="Select client"
        onChange={(c) => navigate(`/client/${c.value}`)}
        // size="sm"
        useBasicStyles
        variant='unstyled'
        m="10"
      />
    </Box>
  )
}
