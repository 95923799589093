import {
  Card,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { customCurrencyFormat } from "../../utils";
import Scrollbar from "../generic/scrollBar";

const LowerDetailRow = ({
  name = "-",
  forecast = 0,
  actual = 0,
  ytdAverage = 0,
  bracket = false,
}) => {
  return (
    <Tr>
      <Td
        border="2px solid"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {name}
      </Td>
      <Td
        textAlign="right"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? forecast
            ? `(${forecast})`
            : `(${forecast})`
          : forecast
          ? `${forecast}`
          : `${forecast}`}
      </Td>
      <Td
        textAlign="right"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? actual
            ? `(${actual})`
            : `(${actual})`
          : actual
          ? `${actual}`
          : `${actual}`}
      </Td>
      <Td
        textAlign="right"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? ytdAverage
            ? `(${ytdAverage})`
            : `(${ytdAverage})`
          : ytdAverage
          ? `${ytdAverage}`
          : `${ytdAverage}`}
      </Td>
    </Tr>
  );
};

const DetailRow = ({
  name = "-",
  forecast = 0,
  actual = 0,
  ytdAverage = 0,
  bracket = false,
  vendor = false,
  isOpen,
  expenseSetter,
  expenseType,
  vendorExpenseSetter,
  vendorExpense,
  ytdEmpty = false,
}) => {
  return (
    <Tr>
      <Td border="2px solid" borderColor="gray.200" p={"7px 20px"}>
        {name}
      </Td>
      <Td
        textAlign="right"
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? forecast
            ? `(${forecast})`
            : `(${forecast})`
          : forecast
          ? `${forecast}`
          : `${forecast}`}
      </Td>
      {!vendor ? (
        <Td
          textAlign="right"
          border="2px solid"
          borderColor="gray.200"
          p={"7px 20px"}
        >
          {bracket
            ? actual
              ? `(${actual})`
              : `(${actual})`
            : actual
            ? `${actual}`
            : `${actual}`}
        </Td>
      ) : (
        <Td
          cursor={"pointer"}
          _hover={{ bg: "#97B1FF" }}
          onClick={() => {
            isOpen(true);
            expenseSetter(expenseType);
            vendorExpenseSetter(vendorExpense);
          }}
          textAlign="right"
          border="2px solid"
          borderColor="gray.200"
          p={"7px 20px"}
        >
          {bracket
            ? actual
              ? `(${actual})`
              : `(${actual})`
            : actual
            ? `${actual}`
            : `${actual}`}
        </Td>
      )}
      <Td
        textAlign="right"
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {!ytdEmpty
          ? bracket
            ? ytdAverage
              ? `(${ytdAverage})`
              : `(${ytdAverage})`
            : ytdAverage
            ? `${ytdAverage}`
            : `${ytdAverage}`
          : ""}
      </Td>
    </Tr>
  );
};

const PopulateCashTable = ({ data }) => {
  return (
    <Tbody>
      {Object.keys(data).map((outerKey) =>
        Object.entries(data[outerKey]).map(([innerKey, value]) =>
          innerKey === "ratio" ? (
            <DetailRow
              key={"ratio"}
              name={"Expense Cover Ratio"}
              forecast={customCurrencyFormat(
                value.earliest_ratio,
                false,
                true,
                false
              )}
              actual={customCurrencyFormat(
                value.latest_ratio,
                false,
                true,
                false
              )}
              ytdEmpty={true}
            />
          ) : innerKey === "Total Movement" ? (
            <LowerDetailRow
              key={innerKey}
              name={innerKey}
              forecast={customCurrencyFormat(
                value.earliest_balance,
                false,
                true
              )}
              actual={customCurrencyFormat(value.latest_balance, false, true)}
              ytdAverage={customCurrencyFormat(
                value.total_movement,
                false,
                true
              )}
            />
          ) : outerKey === "credit_cards" ? (
            <DetailRow
              key={innerKey}
              name={innerKey}
              forecast={customCurrencyFormat(
                value.earliest_balance,
                false,
                true
              )}
              actual={customCurrencyFormat(value.latest_balance, false, true)}
              ytdAverage={customCurrencyFormat(
                value.total_movement,
                false,
                true
              )}
            />
          ) : (
            <DetailRow
              key={innerKey}
              name={innerKey}
              forecast={customCurrencyFormat(
                value.earliest_balance,
                false,
                true
              )}
              actual={customCurrencyFormat(value.latest_balance, false, true)}
              ytdAverage={customCurrencyFormat(
                value.total_movement,
                false,
                true
              )}
            />
          )
        )
      )}
    </Tbody>
  );
};

const DetailView = ({ customer_id, dashDate, trigger, triggerSetter }) => {
  const formattedDate = dashDate.toISOString().split("T")[0];
  const [ytdData, setYtdData] = useState({
    income: "0.00",
    cogs: "0.00",
    gross_profit: "0.00",
    marketing: "0.00",
    team: "0.00",
    practice_costs: "0.00",
    overhead: "0.00",
    business_development: "0.00",
    expense: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
  });

  const [actualData, setActualData] = useState({
    income: "0.00",
    cogs: "0.00",
    gross_profit: "0.00",
    marketing: "0.00",
    team: "0.00",
    practice_costs: "0.00",
    overhead: "0.00",
    business_development: "0.00",
    expense: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
  });

  const [forecastData, setForecastData] = useState({
    income: "0.00",
    cogs: "0.00",
    gross_profit: "0.00",
    marketing: "0.00",
    team: "0.00",
    practice_costs: "0.00",
    overhead: "0.00",
    business_development: "0.00",
    expense: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
  });

  const [cashData, setCashData] = useState({
    "Bank Accounts": {},
    "Credit Cards": {},
    "Total Movement": {},
  });

  const [balanceSheetData, setBalanceSheetData] = useState({
    acc_recievable: "0.00",
    last_acc_recievable: "0.00",
    bank_accounts: "0.00",
    last_bank_accounts: "0.00",
    acc_payable: "0.00",
    last_acc_payable: "0.00",
    credit_cards: "0.00",
    last_credit_cards: "0.00",
    fixed_assets: "0.00",
    last_fixed_assets: "0.00",
    current_assets: "0.00",
    last_current_assets: "0.00",
    long_term_liabilities: "0.00",
    last_long_term_liabilities: "0.00",
    current_liabilities: "0.00",
    last_current_liabilities: "0.00",
    equity: "0.00",
    last_equity: "0.00",

    acc_recievable_movement: "0.00",
    bank_accounts_movement: "0.00",
    acc_payable_movement: "0.00",
    credit_cards_movement: "0.00",
    fixed_assets_movement: "0.00",
    current_assets_movement: "0.00",
    long_term_liabilities_movement: "0.00",
    current_liabilities_movement: "0.00",
    equity_movement: "0.00",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expense, setExpense] = useState("");
  const [vendorExpense, setVendorExpense] = useState("");
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshActualData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/actualvalues/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setActualData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshActualData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshYtdData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/ytdaverage/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setYtdData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshYtdData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshForecastData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/detailforecasts/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setForecastData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshForecastData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshCashData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/cashmovement/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setCashData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshCashData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshBalanceSheetData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/balancesheetoverview/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setBalanceSheetData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshBalanceSheetData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  return (
    <>
      <VendorModal
        isOpen={isModalOpen}
        toggle={toggle}
        title={`${expense} Summary`}
        formattedDate={formattedDate}
        expenseType={vendorExpense}
        id={customer_id}
      />
      <Stack
        width="100%"
        align="left"
        p="20px 30px"
        mt={isMobile ? "175px" : "125px"}
        overflowY={"auto"}
      >
        <Stack>
          <Text fontSize={"25px"} p={"0px 0px 0px 15px"}>
            Profit & Loss Breakdown
          </Text>
          <Card width="100%" height="100%" borderRadius="20px">
            <TableContainer borderRadius="20px">
              <Table variant="unstyled">
                <Thead
                  position="sticky"
                  top={-1}
                  zIndex={1}
                  borderRadius="20px"
                >
                  <Tr
                    padding={"10px 0px"}
                    backgroundColor={"#30384A"}
                    textColor={"white"}
                  >
                    <Td fontSize={"18px"}>Category</Td>
                    <Td fontSize={"18px"}>Forecast</Td>
                    <Td fontSize={"18px"}>Actual</Td>
                    <Td fontSize={"18px"}>YTD Average</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <DetailRow
                    name="Income"
                    forecast={customCurrencyFormat(
                      forecastData ? forecastData.income_forecast : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.income : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.income : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="products (COGS)"
                    forecast={customCurrencyFormat(
                      forecastData ? forecastData.cogs_forecast : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.cogs : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.cogs : "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Cogs"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"cogs"}
                  />

                  <LowerDetailRow
                    name="Gross Profit"
                    forecast={customCurrencyFormat(
                      forecastData
                        ? forecastData.gross_profit_forecast
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.gross_profit : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.gross_profit : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Marketing"
                    forecast={customCurrencyFormat(
                      forecastData ? forecastData.marketing_forecast : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.marketing : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.marketing : "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Marketing"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"marketing"}
                  />
                  <DetailRow
                    name="Team"
                    forecast={customCurrencyFormat(
                      forecastData ? forecastData.team_forecast : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.team : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.team : "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Team"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"team"}
                  />
                  <DetailRow
                    name="Practice Costs"
                    forecast={customCurrencyFormat(
                      forecastData
                        ? forecastData.practice_cost_forecast
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.practice_costs : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.practice_costs : "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Practice Costs"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"practiceCosts"}
                  />
                  <DetailRow
                    name="Overhead"
                    forecast={customCurrencyFormat(
                      forecastData ? forecastData.overhead_forecast : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.overhead : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.overhead : "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Overheads"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"overheads"}
                  />
                  <DetailRow
                    name="Business Development"
                    forecast={customCurrencyFormat(
                      forecastData
                        ? forecastData.business_development_forecast
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.business_development : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.business_development : "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Business Development"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"businessDevelopment"}
                  />
                  <LowerDetailRow
                    name="Total Expenses"
                    forecast={customCurrencyFormat(
                      forecastData ? forecastData.expense_forecast : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.expense : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.expense : "0.00",
                      false,
                      true
                    )}
                  />
                  <LowerDetailRow
                    name="Net Operating Profit"
                    forecast={customCurrencyFormat(
                      forecastData
                        ? forecastData.net_operating_profit_forecast
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.profit : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.profit : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Net Profit Margin"
                    forecast={customCurrencyFormat(
                      forecastData
                        ? forecastData.net_profit_margin_forecast
                        : 0.0,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData ? actualData.profit_percentage : 0.0,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData ? ytdData.profit_percentage : 0.0,
                      true
                    )}
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          <Text fontSize={"25px"} p={"0px 0px 0px 15px"}>
            Cash Movement
          </Text>
          <Card width="100%" height="100%" borderRadius="20px">
            <TableContainer borderRadius="20px">
              <Table variant="unstyled">
                <Thead
                  position="sticky"
                  top={-1}
                  zIndex={1}
                  borderRadius="20px"
                >
                  <Tr
                    padding={"10px 0px"}
                    backgroundColor={"#30384A"}
                    textColor={"white"}
                  >
                    <Td fontSize={"18px"}>Account</Td>
                    <Td fontSize={"18px"}>Start of Month Balance</Td>
                    <Td fontSize={"18px"}>End of Month Balance</Td>
                    <Td fontSize={"18px"}>Total Movement</Td>
                  </Tr>
                </Thead>
                <PopulateCashTable data={cashData} />
              </Table>
            </TableContainer>
          </Card>
          <Text fontSize={"25px"} p={"0px 0px 0px 15px"}>
            Balance Sheet Overview
          </Text>
          <Card width="100%" height="100%" borderRadius="20px">
            <TableContainer borderRadius="20px">
              <Table variant="unstyled">
                <Thead
                  position="sticky"
                  top={-1}
                  zIndex={1}
                  borderRadius="20px"
                >
                  <Tr
                    padding={"10px 0px"}
                    backgroundColor={"#30384A"}
                    textColor={"white"}
                  >
                    <Td fontSize={"18px"}>Category</Td>
                    <Td fontSize={"18px"}>Previous Month</Td>
                    <Td fontSize={"18px"}>Current Month</Td>
                    <Td fontSize={"18px"}>Movement</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <DetailRow
                    name="Accounts Recievable"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_acc_recievable
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.acc_recievable
                        : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.acc_recievable_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Bank Accounts"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_bank_accounts
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.bank_accounts
                        : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.bank_accounts_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Accounts Payable"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_acc_payable
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.acc_payable : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.acc_payable_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Credit Cards"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_credit_cards
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.credit_cards : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.credit_cards_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Fixed Assets"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_fixed_assets
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.fixed_assets : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.fixed_assets_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Current Assets"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_current_assets
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.current_assets
                        : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.current_assets_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Long-Term Liabilities"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_long_term_liabilities
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.long_term_liabilities
                        : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.long_term_liabilities_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Current Liabilities"
                    forecast={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.last_current_liabilities
                        : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.current_liabilities
                        : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.current_liabilities_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                  <LowerDetailRow
                    name="Equity"
                    forecast={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.last_equity : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.equity : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.equity_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Stack>
    </>
  );
};

export default DetailView;

const VendorModal = ({
  isOpen,
  id,
  toggle,
  title,
  formattedDate,
  expenseType,
}) => {
  const contentRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [vendorData, setVendorData] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshVendorData = async (id) => {
      if (id) {
        if (expenseType) {
          try {
            const res = await axios.get(
              `/api/v2/customers/${id}/vendorbreakdown/${formattedDate}/${expenseType}`,
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
                signal: controller.signal,
              }
            );
            if (isActive) {
              setVendorData(res.data);
            }
          } catch (err) {
            if (err?.response) {
              console.log(err);
            }
          }
        } else {
          setVendorData({});
        }
      } else {
        setVendorData({});
      }
    };

    refreshVendorData(id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [id, formattedDate, expenseType]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasOverflowY =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setHasOverflow(hasOverflowY);
        if (hasOverflowY) {
          const contentHeight = contentRef.current.scrollHeight;
          const visibleHeight = contentRef.current.clientHeight;
          setScrollHeight((visibleHeight / contentHeight) * 100);
        }
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [vendorData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      size="sm"
      position={"sticky"}
      scrollBehavior="inside"
      isCentered
      height="100vh"
    >
      <ModalOverlay overflowY={"hidden"} />
      <ModalContent borderRadius="15px" overflow="hidden" maxHeight={"90vh"}>
        <ModalHeader p={"20px 20px 10px 20px"}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0px 20px 20px 20px">
          <Flex flexDirection={"column"} position="relative">
            <TableContainer
              ref={contentRef}
              height={"100%"}
              maxHeight="60vh"
              overflowY="hidden"
              borderRadius="20px"
              position="relative"
              marginRight={hasOverflow ? "20px" : 0}
            >
              <Card borderRadius="20px">
                <Table
                  variant="unstyled"
                  bg="white"
                  padding={0}
                  rounded={"20px"}
                >
                  <Thead position="sticky" top={0} zIndex={3} bg="#30384A">
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td>Vendor</Td>
                      <Td>Amount</Td>
                    </Tr>
                  </Thead>

                  <Tbody zIndex={1}>
                    {vendorData?.vendors?.length > 0 ? (
                      vendorData.vendors.map((vendor) => (
                        <Tr key={vendor.id}>
                          <Td border="2px solid" borderColor="gray.200">
                            <HStack>
                              <Flex align="center" justify="center">
                                <Image
                                  src={
                                    vendor.logo ||
                                    `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                                  }
                                  alt={vendor.name || "Placeholder"}
                                  boxSize="30px"
                                  objectFit="cover"
                                  borderRadius="10px"
                                  mr={"2"}
                                />

                                {vendor.name}
                              </Flex>
                            </HStack>
                          </Td>
                          <Td
                            border="2px solid"
                            backgroundColor={"#F6F8FE"}
                            borderColor="gray.200"
                          >
                            {customCurrencyFormat(vendor.amount, false, true)}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr
                        border="2px solid"
                        borderColor="gray.200"
                        height={"20px"}
                      >
                        <Td colSpan={2} textAlign="center">
                          No vendor data.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>

                  <Tfoot position="sticky" bottom={0} zIndex={3} bg="#30384A">
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td>Total</Td>
                      <Td>
                        {customCurrencyFormat(
                          vendorData?.total ? vendorData.total : "0",
                          false,
                          true
                        )}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </Card>
            </TableContainer>

            {hasOverflow ? (
              <Scrollbar
                targetRef={contentRef}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  height: "100%",
                  visibility: hasOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    height: `${scrollHeight}%`,
                    visibility: hasOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
