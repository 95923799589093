import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import Scrollbar from "../generic/scrollBar";
import HorizontalScrollbar from "../generic/scrollBarX";
import { customCurrencyFormat } from "../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ExpenseBreakdown = ({
  customer_id,
  dashDate,
  setExpenseBreakdown,
  title,
  type,
  setTab,
  selectedPeriod,
  setSelectedPeriod,
}) => {
  const formattedDate = dashDate.toISOString().split("T")[0];
  const [metricGraphData, setMetricGraphData] = useState({
    values: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    date_interval: ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  });

  const contentRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [hasXOverflow, setHasXOverflow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [vendorData, setVendorData] = useState({});
  const [vendorYearData, setVendorYearData] = useState({});
  const [optionalWidthX, setOptionalWidthX] = useState(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasOverflowX =
          contentRef.current.scrollWidth > contentRef.current.clientWidth;
        setHasXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentRef.current.scrollWidth;
          const visibleWidth = scrollContainerRef.current.clientWidth;
          setOptionalWidthX(visibleWidth);

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollWidth(thumbWidth);
        }
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [vendorData, selectedPeriod, setOptionalWidthX]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshVendorData = async (id) => {
      if (id) {
        if (type) {
          try {
            let endpoint;
            if (selectedPeriod === "month") {
              endpoint = `/api/v2/customers/${id}/vendorbreakdown/${formattedDate}/${
                type === "overhead" ? "overheadFull" : type
              }`;
            } else {
              endpoint = `/api/v2/customers/${id}/vendorexpensebreakdown/${formattedDate}/${
                type === "overhead" ? "overheads" : type
              }`;
            }

            const res = await axios.get(endpoint, {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            });

            if (isActive) {
              if (selectedPeriod === "month") {
                setVendorData(res.data);
              } else {
                setVendorYearData(res.data);
              }
            }
          } catch (err) {
            if (err?.response) {
              console.log(err);
            }
          }
        } else {
          setVendorData({});
        }
      } else {
        setVendorData({});
      }
    };

    refreshVendorData(customer_id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, formattedDate, type, selectedPeriod]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasOverflowY =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setHasOverflow(hasOverflowY);
        if (hasOverflowY) {
          const contentHeight = contentRef.current.scrollHeight;
          const visibleHeight = contentRef.current.clientHeight;
          setScrollHeight((visibleHeight / contentHeight) * 100); // Set scrollbar thumb height as a percentage
        }
      }
    };

    // Run the check initially and on window resize
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [vendorData, selectedPeriod]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshMetricGraphData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/expenseoverviewgraph/${type}/${selectedPeriod}/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setMetricGraphData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshMetricGraphData(customer_id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, formattedDate, selectedPeriod, type]);

  const data = {
    labels: metricGraphData
      ? metricGraphData.date_interval
      : ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
    datasets: [
      {
        type: "line",
        label: "Expense Trend Line",
        borderColor: "#FF5A5C",
        color: "#FF5A5C",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return "#FF5A5C";
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(255, 90, 92, 0.7)");
          gradient.addColorStop(1, "rgba(255, 90, 92, 0.1)");
          return gradient;
        },
        borderWidth: 3,
        pointRadius: 0,
        fill: true,
        data: metricGraphData
          ? metricGraphData.values
          : [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        yAxisID: "y",
        order: "1",
        hoverRadius: 4,
        pointHitRadius: 30,
        borderCapStyle: "round",
        borderDash: [3, 2],
        PointStyle: "circle",
        tension: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            if (value < 1000) {
              return value % 1 === 0 ? `$${value}` : `$${value.toFixed(2)}`;
            } else {
              return value % 1 === 0
                ? `${(value / 1000).toFixed(0)}k`
                : `${(value / 1000).toFixed(1)}k`;
            }
          },
        },
        min: 0,
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: true,
          tickLength: 10,
          color: "#CCCCCC",
          lineWidth: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align: "end",
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: false,
        position: "nearest",
        backgroundColor: "white",
        borderColor: "#e2e8f0",
        boxShadow: "md",
        borderWidth: 1,
        displayColors: true,
        xAlign: "left",
        yAlign: "center",
        caretSize: 0,
        caretPadding: 20,
        paddingLeft: 12,
        fontWeight: 100,
        fontSize: 12,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            const label = tooltipItem.label;

            return ` ${
              selectedPeriod === "year"
                ? label
                : label +
                  "/" +
                  (dashDate.getMonth() + 1).toString().padStart(2, "0")
            }: ${customCurrencyFormat(value, false, true)}`;
          },

          labelColor: function (context) {
            return {
              borderColor: "#FF5A5C",
              backgroundColor: "#FF5A5C",
              borderWidth: 0,
              borderRadius: 6,
              align: "center",
            };
          },
        },
        titleFont: {
          size: 0, // Hide title font
        },
        bodyFont: {
          size: 12,
          weight: "bold",
        },
        bodyColor: "black",
        boxWidth: 14,
        boxHeight: 14,
        boxBorder: 0,
      },
    },
    layout: {
      padding: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
    },
  };
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  return (
    <Stack>
      <Card
        mt={isMobile ? "75px" : "0px"}
        borderRadius={0}
        width="100%"
        h="75px"
        border="1px solid #e0e0e0"
        mb={1}
      >
        <SimpleGrid
          templateColumns={isMobile ? "1fr" : "2fr 2fr"}
          top={"50%"}
          padding={"20px"}
        >
          <Box
            gridRow={1}
            gridColumn={1}
            textAlign={"left"}
            fontWeight={500}
            fontSize={"clamp(24px, 2vw, 28px)"}
          >
            {" "}
            {title} Breakdown
          </Box>
          <Box gridRow={1} gridColumn={2} textAlign={"right"}>
            {!isMobile && (
              <ButtonGroup isAttached variant="outline">
                <Button
                  _hover={{
                    background: "initial",
                    boxShadow: "none",
                    opacity: "100%",
                    backgroundColor:
                      selectedPeriod === "month" ? "#536DEC" : "white",
                  }}
                  backgroundColor={
                    selectedPeriod === "month" ? "#536DEC" : "white"
                  }
                  textColor={selectedPeriod === "month" ? "white" : "#536DEC"}
                  onClick={() => setSelectedPeriod("month")}
                >
                  Current Month
                </Button>
                <Button
                  _hover={{
                    background: "initial",
                    boxShadow: "none",
                    opacity: "100%%",
                    backgroundColor:
                      selectedPeriod === "year" ? "#536DEC" : "white",
                  }}
                  backgroundColor={
                    selectedPeriod === "year" ? "#536DEC" : "white"
                  }
                  textColor={selectedPeriod === "year" ? "white" : "#536DEC"}
                  onClick={() => setSelectedPeriod("year")}
                >
                  Year to Date
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </SimpleGrid>
      </Card>
      <Stack align={"center"}>
        <Box width="95%">
          <Text
            textColor={"#536DEC"}
            cursor={"pointer"}
            fontSize={"17px"}
            paddingBottom={"15px"}
            paddingTop={"10px"}
            onClick={() => {
              setExpenseBreakdown(false);
              setTab("performanceReview");
              setSelectedPeriod("month");
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faLongArrowLeft} strokeWidth={0.1} /> Back to
            Metric View
          </Text>
          <Card height={"300px"} width={"100%"}>
            <CardHeader paddingBottom={0}>
              <SimpleGrid templateColumns={"2fr 2fr"}>
                <Text fontSize={"17px"} textAlign={"left"}>{`${
                  selectedPeriod === "year"
                    ? "12-Month"
                    : dashDate.toLocaleString("default", {
                        month: "long",
                      })
                } ${title} Expense Trend`}</Text>
                <Box textAlign={"right"} position={"relative"}>
                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    position={"absolute"}
                    bottom={0}
                    right={0}
                  >
                    <Box
                      width="18px"
                      borderRadius={"5px"}
                      height="5px"
                      padding={"0 4px"}
                      backgroundColor="#FF5A5C"
                      marginRight="5px"
                    />
                    <Text fontSize={"14px"}>Expense Trend Line</Text>
                  </Flex>
                </Box>
              </SimpleGrid>
            </CardHeader>
            <CardBody>
              <Line
                data={data}
                options={options}
                plugins={[
                  {
                    afterDraw: (chart) => {
                      if (chart.tooltip?._active?.length) {
                        let x = chart.tooltip._active[0].element.x;
                        let yAxis = chart.scales.y;
                        let ctx = chart.ctx;
                        ctx.save();
                        ctx.beginPath();
                        ctx.setLineDash([5, 5]);
                        ctx.moveTo(x, yAxis.top);
                        ctx.lineTo(x, yAxis.bottom);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = "black";
                        ctx.stroke();
                        ctx.restore();
                      }
                    },
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Box>
      </Stack>
      <Stack align={"center"} pt={2} pb={2}>
        <Flex flexDirection={"column"} position="relative" width={"95%"}>
          <Card
            borderRadius="20px"
            outline={"2px solid"}
            outlineColor={"gray.200"}
            marginRight={hasOverflow ? "20px" : 0}
          >
            <TableContainer
              ref={contentRef}
              height={"100%"}
              maxHeight="42vh"
              overflow="hidden"
              borderRadius="20px"
              position="relative"
            >
              {selectedPeriod === "month" ? (
                <Table
                  variant="unstyled"
                  bg="white"
                  padding={0}
                  rounded={"20px"}
                >
                  <Thead position="sticky" top={0} zIndex={3} bg="#30384A">
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td>Vendor</Td>
                      <Td>Amount</Td>
                    </Tr>
                  </Thead>

                  <Tbody zIndex={1}>
                    {vendorData?.vendors?.length > 0 ? (
                      vendorData.vendors.map((vendor) => (
                        <Tr key={vendor.id}>
                          <Td border="2px solid" borderColor="gray.200">
                            <HStack>
                              <Flex align="center" justify="center">
                                <Image
                                  src={
                                    vendor.logo ||
                                    `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                                  }
                                  alt={vendor.name || "Placeholder"}
                                  boxSize="30px"
                                  objectFit="cover"
                                  borderRadius="10px"
                                  mr={"2"}
                                />

                                {vendor.name}
                              </Flex>
                            </HStack>
                          </Td>
                          <Td
                            border="2px solid"
                            backgroundColor={"#F6F8FE"}
                            borderColor="gray.200"
                          >
                            {customCurrencyFormat(vendor.amount, false, true)}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr
                        border="2px solid"
                        borderColor="gray.200"
                        height={"20px"}
                      >
                        <Td colSpan={2} textAlign="center">
                          No vendor data.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>

                  <Tfoot position="sticky" bottom={0} zIndex={3} bg="#30384A">
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td>Total</Td>
                      <Td>
                        {customCurrencyFormat(
                          vendorData?.total ? vendorData.total : "0",
                          false,
                          true
                        )}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>
              ) : (
                <Table
                  variant="unstyled"
                  bg="white"
                  padding={0}
                  border-collapse="separate"
                  border-spacing={"2px"}
                >
                  <Thead position="sticky" top={0} bg="#30384A" zIndex={5}>
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td
                        textAlign="left"
                        position={"sticky"}
                        bg="#30384A"
                        left={0}
                        zIndex={4}
                      >
                        Vendor
                      </Td>
                      {vendorYearData?.months
                        ? vendorYearData.months.map((month, index) => (
                            <Td key={index}>{month}</Td>
                          ))
                        : Array.from({ length: 12 }, (_, index) => (
                            <Td key={index} textAlign="left">
                              {" "}
                              -{" "}
                            </Td>
                          ))}
                      <Td
                        textAlign="left"
                        position={"sticky"}
                        bg="#30384A"
                        right={0}
                        zIndex={4}
                      >
                        Total
                      </Td>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {vendorYearData?.vendors &&
                    vendorYearData?.vendors.length > 0 ? (
                      vendorYearData.vendors.map((vendor) => (
                        <Tr
                          key={vendor.id}
                          outline={"1px solid"}
                          outlineColor="gray.200"
                        >
                          <Td
                            textAlign="left"
                            position={"sticky"}
                            left={0}
                            zIndex={4}
                            backgroundColor={"white"}
                            color={"black"}
                            outline="2px solid"
                            outlineColor="gray.200"
                          >
                            <HStack>
                              <Flex align="center" justify="center">
                                <Image
                                  src={
                                    vendor.logo ||
                                    `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                                  }
                                  alt={vendor.name || "Placeholder"}
                                  boxSize="30px"
                                  objectFit="cover"
                                  borderRadius="10px"
                                  mr={"2"}
                                />

                                {vendor.name}
                              </Flex>
                            </HStack>
                          </Td>
                          {vendor.monthly_amounts.map((amount, index) => (
                            <Td
                              key={index}
                              textAlign="left"
                              color={"black"}
                              outline="1px solid"
                              outlineColor="gray.200"
                            >
                              {customCurrencyFormat(amount, false, true)}
                            </Td>
                          ))}
                          <Td
                            textAlign="left"
                            position={"sticky"}
                            right={0}
                            backgroundColor={"#F6F8FE"}
                            color={"black"}
                            outline="2px solid"
                            outlineColor="gray.200"
                            zIndex={3}
                          >
                            {customCurrencyFormat(vendor.total, false, true)}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td
                          textAlign="left"
                          position={"sticky"}
                          left={0}
                          zIndex={4}
                          backgroundColor={"white"}
                          color={"black"}
                          outline="2px solid"
                          outlineColor="gray.200"
                        >
                          No vendor data
                        </Td>
                        {Array.from({ length: 12 }, (_, index) => (
                          <Td
                            key={index}
                            textAlign="left"
                            color={"black"}
                            outline="1px solid"
                            outlineColor="gray.200"
                          >
                            {" "}
                            -{" "}
                          </Td>
                        ))}
                        <Td
                          textAlign="left"
                          position={"sticky"}
                          right={0}
                          backgroundColor={"#F6F8FE"}
                          color={"black"}
                          outline="2px solid"
                          outlineColor="gray.200"
                          zIndex={3}
                        >
                          -
                        </Td>
                      </Tr>
                    )}
                  </Tbody>

                  <Tfoot
                    bg="#30384A"
                    fontSize="18px"
                    sx={{
                      "& td": {
                        fontWeight: "200",
                      },
                    }}
                  >
                    {vendorYearData?.total ? (
                      <Tr>
                        <Td
                          textAlign="left"
                          color={"white"}
                          position={"sticky"}
                          bg="#30384A"
                          left={0}
                          bottom={0}
                          zIndex={4}
                          borderColor="gray.200"
                        >
                          Total
                        </Td>
                        {vendorYearData.total.monthly_amounts.map(
                          (amount, index) => (
                            <Td
                              key={index}
                              textAlign="left"
                              position="sticky"
                              bottom={0}
                              backgroundColor={"#F6F8FE"}
                              color={"black"}
                              outline="2px solid"
                              outlineColor="gray.200"
                            >
                              {customCurrencyFormat(amount, false, true)}
                            </Td>
                          )
                        )}
                        <Td
                          textAlign="left"
                          color={"white"}
                          position={"sticky"}
                          right={0}
                          bottom={0}
                          bg="#30384A"
                          zIndex={4}
                        >
                          {customCurrencyFormat(
                            vendorYearData.total.total,
                            false,
                            true
                          )}
                        </Td>
                      </Tr>
                    ) : (
                      <Tr>
                        <Td
                          textAlign="left"
                          color={"white"}
                          position={"sticky"}
                          bg="#30384A"
                          left={0}
                          bottom={0}
                          zIndex={4}
                          borderColor="gray.200"
                        >
                          Total
                        </Td>
                        {Array.from({ length: 12 }, (_, index) => (
                          <Td
                            key={index}
                            textAlign="left"
                            position="sticky"
                            bottom={0}
                            backgroundColor={"#F6F8FE"}
                            color={"black"}
                            outline="2px solid"
                            outlineColor="gray.200"
                          >
                            {" "}
                            -{" "}
                          </Td>
                        ))}
                        <Td
                          textAlign="left"
                          color={"white"}
                          position={"sticky"}
                          right={0}
                          bottom={0}
                          bg="#30384A"
                          zIndex={4}
                        >
                          -
                        </Td>
                      </Tr>
                    )}
                  </Tfoot>
                </Table>
              )}
            </TableContainer>
          </Card>
          {hasOverflow ? (
            <Box>
              <Scrollbar
                targetRef={contentRef}
                sx={{
                  position: "absolute",
                  right: 0, // Align to the right
                  top: 0,
                  height: "100%",
                  visibility: hasOverflow ? "visible" : "hidden", // Hide scrollbar if no overflow
                  "&::-webkit-scrollbar-thumb": {
                    height: `${scrollHeight}%`, // Adjust the thumb size based on content height
                    visibility: hasOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasOverflow ? "visible" : "hidden", // Hide track if no overflow
                  },
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </Flex>
        <Box
          position={"relative"}
          width={"95%"}
          marginTop={"10px"}
          ref={scrollContainerRef}
        >
          {hasXOverflow ? (
            <HorizontalScrollbar
              targetRef={contentRef}
              optional_width={optionalWidthX}
              sx={{
                position: "absolute",
                right: 0, // Align to the right
                top: 0,
                width: "100%",
                height: "100%",
                visibility: hasXOverflow ? "visible" : "hidden", // Hide scrollbar if no overflow
                "&::-webkit-scrollbar-thumb": {
                  width: `${scrollWidth}%`, // Adjust the thumb size based on content height
                  visibility: hasXOverflow ? "visible" : "hidden",
                },
                "&::-webkit-scrollbar-track": {
                  visibility: hasXOverflow ? "visible" : "hidden", // Hide track if no overflow
                },
              }}
            />
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ExpenseBreakdown;
