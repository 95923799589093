import { Box, Card, CardBody, SimpleGrid, Stack, Text } from "@chakra-ui/react";

import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const MetricExpenseCard = ({
  title = "",
  percentage = "0.00",
  total = "0.00",
  perfectPL = "0.00",
  color = "black",
  type = "",
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  return (
    <Stack width="100%" align="center" p="10px 0px">
      <Card
        width="100%"
        height={"180px"}
        borderRadius="20px"
        onClick={() => {
          setBreakdownData({ title: title, type: type });
          triggerExpenseBreakdown(true);
        }}
        _hover={{ boxShadow: "xl", cursor: "pointer" }}
      >
        <CardBody padding={5}>
          <Box align={"left"}>
            <SimpleGrid>
              <Text
                gridColumn={1}
                gridRow={1}
                fontSize={"large"}
                margin={0}
                textAlign={"left"}
              >
                {title}
              </Text>
              <Box
                gridColumn={2}
                gridRow={1}
                color="#536DEC"
                textAlign={"right"}
              >
                <FontAwesomeIcon
                  icon={faLongArrowRight}
                  strokeWidth={0.1}
                  fontSize={30}
                />
              </Box>
            </SimpleGrid>
            <Text fontSize={"32px"} fontWeight={800} margin={0} color={color}>
              {percentage}
            </Text>
          </Box>
          <SimpleGrid
            templateColumns={"14fr 5fr 14fr"}
            align="center"
            p={"10px 0px"}
          >
            <Box
              p={0}
              height={"60px"}
              gridColumn={1}
              gridRow={1}
              textAlign={"left"}
            >
              <Text fontSize={"md"} margin={0}>
                Perfect P&L
              </Text>
              <Text
                fontSize={"clamp(18px, 5vw, 22px)"}
                fontWeight={600}
                margin={0}
                gridColumn={1}
                gridRow={2}
                whiteSpace={"nowrap"}
              >
                Below {perfectPL}
              </Text>
            </Box>
            <Box
              textAlign={"center"}
              align={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Box
                gridColumn={2}
                gridRow={1}
                height={"100%"}
                border={"1px"}
                width="0px"
                borderColor={"gray.200"}
              ></Box>
            </Box>
            <Box gridColumn={3} gridRow={1} textAlign={"left"}>
              <Text fontSize={"md"} margin={0}>
                Total
              </Text>
              <Text
                fontSize={"clamp(18px, 5vw, 22px)"}
                fontWeight={600}
                margin={0}
                gridColumn={2}
                gridRow={2}
                whiteSpace={"nowrap"}
              >
                {total}
              </Text>
            </Box>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default MetricExpenseCard;
