import axios from "axios";

if (process.env.REACT_APP_DOMAIN) {
  axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;
}

let refresh = false;
axios.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error.request.responseURL.indexOf("/api/v2/token/") > 0) {
      return;
    }
    if (error.response.status === 401 && !refresh) {
      refresh = true;
      axios
        .post(
          "/api/v2/token/refresh/",
          {
            refresh: localStorage.getItem("refresh_token"),
          },
          {
            headers: { "Content-Type": "application/json" },
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (!response || response.status !== 200) {
            window.location.href = "/login";
            localStorage.setItem("access_token", null);
            window.dispatchEvent(new Event("storage"));
          } else {
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
            return axios(error.config);
          }
        });
    }
    refresh = false;
    return error;
  }
);

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
