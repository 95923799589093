import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

const Scrollbar = ({ targetRef }) => {
  const scrollbarThumbRef = useRef(null);
  const scrollbarContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current && scrollbarThumbRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = targetRef.current;
        const thumbHeight = scrollbarThumbRef.current.offsetHeight;
        const scrollRatio = scrollTop / (scrollHeight - clientHeight);
        const thumbTop = scrollRatio * (clientHeight - thumbHeight);
        scrollbarThumbRef.current.style.transform = `translateY(${thumbTop}px)`;
      }
    };

    const updateScrollbar = () => {
      if (targetRef.current && scrollbarThumbRef.current) {
        const { scrollHeight, clientHeight } = targetRef.current;
        const scrollbarHeight = scrollbarContainerRef.current.clientHeight;

        const thumbHeight = Math.max(
          (clientHeight / scrollHeight) * scrollbarHeight,
          20 // Minimum height for usability
        );
        scrollbarThumbRef.current.style.height = `${thumbHeight}px`;
      }
    };

    const target = targetRef.current;

    if (target) {
      target.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateScrollbar);
    }
    updateScrollbar();

    return () => {
      if (target) {
        target.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateScrollbar);
      }
    };
  }, [targetRef]);

  const startDrag = (clientY) => {
    setIsDragging(true);
    setStartY(clientY);
    if (targetRef.current) {
      setStartScrollTop(targetRef.current.scrollTop);
    }
    document.body.style.userSelect = "none"; // Prevent text selection during drag
  };

  const handleThumbMouseDown = (e) => {
    e.preventDefault();
    startDrag(e.clientY);
  };

  const handleThumbTouchStart = (e) => {
    const touch = e.touches[0];
    startDrag(touch.clientY);
  };

  useEffect(() => {
    const handleDrag = (clientY) => {
      if (isDragging && targetRef.current && scrollbarContainerRef.current) {
        const deltaY = clientY - startY;

        const target = targetRef.current;
        const { scrollHeight, clientHeight } = target;
        const scrollbarHeight = scrollbarContainerRef.current.clientHeight;

        const scrollableHeight = scrollHeight - clientHeight;
        const thumbScrollableHeight =
          scrollbarHeight - scrollbarThumbRef.current.offsetHeight;

        const scrollDelta = (deltaY / thumbScrollableHeight) * scrollableHeight;

        target.scrollTop = Math.min(
          startScrollTop + scrollDelta,
          scrollableHeight
        );
      }
    };

    const endDrag = () => {
      setIsDragging(false);
      document.body.style.userSelect = ""; // Restore text selection
    };

    const handleMouseUp = () => {
      endDrag();
    };

    const handleTouchEnd = () => {
      endDrag();
    };

    const handleMouseMove = (e) => {
      handleDrag(e.clientY);
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      handleDrag(touch.clientY);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDragging, startScrollTop, startY, targetRef]);

  return (
    <Box
      ref={scrollbarContainerRef}
      sx={{
        position: "absolute",
        right: "0",
        top: "0",
        width: "11px",
        height: "100%",
        backgroundColor: "gray.200",
        borderRadius: "10px",
      }}
    >
      <Box
        ref={scrollbarThumbRef}
        onMouseDown={handleThumbMouseDown}
        onTouchStart={handleThumbTouchStart}
        sx={{
          backgroundColor: "#30384A",
          borderRadius: "10px",
          width: "100%",
          position: "absolute",
          top: "0",
          height: "20px",
          cursor: "pointer",
        }}
      />
    </Box>
  );
};

export default Scrollbar;
