import { Skeleton, Stack } from "@chakra-ui/react";

export const renderAmount = (amount) => {
  if (!amount) {
    amount = 0;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const renderAmountWithType = (amount, type) => {
  if (!amount) {
    amount = 0;
  }

  amount = Math.abs(amount);
  if (type === "debit") {
    amount = amount * -1;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const oppositeType = (type) => {
  if (type === "debit") {
    return "credit";
  } else {
    return "debit";
  }
};

export const isTransactionLinked = (transaction) => {
  if (transaction.journal_entries.length === 0) {
    return false;
  }
  let entriesLinked = transaction.journal_entries.every((e) => e.account);
  return entriesLinked && transaction.vendor;
};

export const isTransactionAdded = (transaction) => {
  if (isTransactionLinked(transaction)) {
    return transaction.journal_entries.every((e) => e.added);
  }
  return false;
};

export const titleCase = (string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const LoadingTable = () => {
  return (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );
};

export const customCurrencyFormat = (
  numStr,
  percentage = false,
  wrapNegative = false,
  includeSymbol = true
) => {
  let num = parseFloat(numStr);
  if (isNaN(num)) return numStr;

  const isNegative = num < 0;

  let absNum = Math.abs(num).toFixed(2);

  let [integerPart, decimalPart] = absNum.split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedNumber =
    decimalPart !== "00" ? `${integerPart}.${decimalPart}` : integerPart;

  // Format the number based on percentage and wrapNegative options
  if (percentage) {
    return isNegative && wrapNegative
      ? `(${formattedNumber}${includeSymbol ? "%" : ""})`
      : isNegative
      ? `-${formattedNumber}${includeSymbol ? "%" : ""}`
      : `${formattedNumber}${includeSymbol ? "%" : ""}`;
  } else {
    return isNegative && wrapNegative
      ? `(${includeSymbol ? "$" : ""}${formattedNumber})`
      : isNegative
      ? `-${includeSymbol ? "$" : ""}${formattedNumber}`
      : `${includeSymbol ? "$" : ""}${formattedNumber}`;
  }
};
