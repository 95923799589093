import { Box, Card, CardBody, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";

const GrowthPlanCard = ({
  leftTitle = "",
  rightTitle = "",
  leftAmount = "$0.00",
  rightAmount = "$0.00",
  rightPercentage = "0%",
  cardHeight = "120px",
  cardWidth = "100%",
  color = "black",
}) => {

  return (
    <Card width={cardWidth} height={cardHeight} borderRadius="20px">
      <CardBody>
        <Box>
          <SimpleGrid templateColumns={"1fr 1fr"} spacing={1} spacingY={0}>
            <Box p={0} borderRightWidth={"2px"} width={"85%"} height={"80px"}>
              <Box gridColumn={1} gridRow={1} p={"0px 0px 2px 0px"}>
                {leftTitle}
              </Box>
              <Box
                gridColumn={1}
                gridRow="span 2"
                fontWeight={800}
                height="auto"
                maxHeight={"30px"}
              >
                <Text whiteSpace={"nowrap"} fontSize={"clamp(18px, calc(1.6cqw - 0.09vw), 30px)"}>{leftAmount}</Text>
              </Box>
            </Box>

            <Box>
              <Box gridColumn={2} gridRow={1} p={"0px 0px 2px 0px"}>
                {rightTitle}
              </Box>

              <Box gridColumn={2} gridRow={2} height="30px">
                <Stack p={0}>
                  <Box whiteSpace={"nowrap"} fontSize={"clamp(16px, calc(1.5cqw - 0.11vw), 25px)"} fontWeight={600} lineHeight={1}>
                    {rightAmount}
                    <Text whiteSpace={"nowrap"} fontSize={"clamp(14px, calc(1.5cqw - 0.11vw), 18px)"} fontWeight={200} p="7px 0px" color={color}>
                      {rightPercentage}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </CardBody>
    </Card>
  );
};

export default GrowthPlanCard;
