import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

const HorizontalScrollbar = ({ targetRef, optional_width = null }) => {
  const scrollbarThumbRef = useRef(null);
  const scrollbarContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current && scrollbarThumbRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = targetRef.current;
        const effectiveWidth = optional_width || clientWidth;
        const thumbWidth = scrollbarThumbRef.current.offsetWidth;
        const scrollRatio = scrollLeft / (scrollWidth - effectiveWidth);
        const thumbLeft =
          scrollRatio * (scrollbarContainerRef.current.clientWidth - thumbWidth);

        scrollbarThumbRef.current.style.transform = `translateX(${thumbLeft}px)`;
      }
    };

    const updateScrollbar = () => {
      if (targetRef.current && scrollbarThumbRef.current) {
        const { scrollWidth, clientWidth } = targetRef.current;
        const effectiveWidth = optional_width || clientWidth;
        const scrollbarWidth = scrollbarContainerRef.current.clientWidth;

        const thumbWidth = Math.max(
          (effectiveWidth / scrollWidth) * scrollbarWidth,
          20 // Minimum width for usability
        );
        scrollbarThumbRef.current.style.width = `${thumbWidth}px`;
      }
    };

    const target = targetRef.current;

    if (target) {
      target.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateScrollbar);
    }
    updateScrollbar();

    return () => {
      if (target) {
        target.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateScrollbar);
      }
    };
  }, [targetRef, optional_width]);

  const startDrag = (clientX) => {
    setIsDragging(true);
    setStartX(clientX);
    if (targetRef.current) {
      setStartScrollLeft(targetRef.current.scrollLeft);
    }
    document.body.style.userSelect = "none"; // Prevent text selection during drag
  };

  const handleThumbMouseDown = (e) => {
    e.preventDefault();
    startDrag(e.clientX);
  };

  const handleThumbTouchStart = (e) => {
    const touch = e.touches[0];
    startDrag(touch.clientX);
  };



  useEffect(() => {
    const handleDrag = (clientX) => {
        if (isDragging && targetRef.current && scrollbarContainerRef.current) {
          const deltaX = clientX - startX;

          const target = targetRef.current;
          const { scrollWidth, clientWidth } = target;
          const effectiveWidth = optional_width || clientWidth;
          const scrollbarWidth = scrollbarContainerRef.current.clientWidth;

          const scrollableWidth = scrollWidth - effectiveWidth;
          const thumbScrollableWidth =
            scrollbarWidth - scrollbarThumbRef.current.offsetWidth;

          const scrollDelta = (deltaX / thumbScrollableWidth) * scrollableWidth;

          target.scrollLeft = Math.min(
            Math.max(startScrollLeft + scrollDelta, 0),
            scrollableWidth
          );
        }
      };

      const handleMouseMove = (e) => {
        handleDrag(e.clientX);
      };

      const handleTouchMove = (e) => {
        const touch = e.touches[0];
        handleDrag(touch.clientX);
      };

      const endDrag = () => {
        setIsDragging(false);
        document.body.style.userSelect = ""; // Restore text selection
      };

      const handleMouseUp = () => {
        endDrag();
      };

      const handleTouchEnd = () => {
        endDrag();
      };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDragging, optional_width, startScrollLeft, startX, targetRef]);

  return (
    <Box
      ref={scrollbarContainerRef}
      sx={{
        position: "absolute",
        bottom: "0", // Position the scrollbar at the bottom
        left: "0",
        width: "100%",
        height: "11px",
        backgroundColor: "gray.200",
        borderRadius: "10px",
      }}
    >
      <Box
        ref={scrollbarThumbRef}
        onMouseDown={handleThumbMouseDown}
        onTouchStart={handleThumbTouchStart}
        sx={{
          backgroundColor: "#30384A",
          borderRadius: "10px",
          height: "100%",
          position: "absolute",
          left: "0",
          width: "20px",
          cursor: "pointer",
        }}
      />
    </Box>
  );
};

export default HorizontalScrollbar;