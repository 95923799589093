import React, { useState } from "react";
import axios from "axios";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Image,
  FormControl,
  Text,
  FormHelperText,
  Link,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import { Context } from "../ContextWrapper";

const CFaUserAlt = chakra(FaUserAlt);

export const ForgotPassword = () => {
  const context = React.useContext(Context);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const user = {
      email: email,
    };
    await axios
      .post(
        "/api/v2/forgotpassword/",
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setDisabled(true);
        context.fireToast(
          "Reset password",
          "Password change email sent.",
          "success"
        );
      });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={submit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <Box w="400px">
                <Image src="lucro-logo-light.png" alt="Dan Abramov" />
              </Box>
              <Text>
                Enter your email and we'll send you a link to reset your
                password
              </Text>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <FormHelperText textAlign="right">
                  <Link href="/login">back to login</Link>
                </FormHelperText>
              </FormControl>

              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="blue"
                width="full"
                isDisabled={disabled}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
